<template>
  <span class="check-style" :class="{active: checked, 'is-disabled': disabled}" @click="setChecked"></span>
</template>

<script>

export default {
  props: ['isChecked', 'disabled'],
  data() {
    return {
      checked: this.isChecked
    }
  },
  methods: {
    setChecked() {
      if(this.disabled) return
      this.checked = !this.checked;
      this.$emit('update:isChecked', this.checked);
    }
  },
  watch:{
    isChecked(val){
      this.checked=val;
    }
  }
}
</script>

<style lang="less">
  .check-style{
    float: left;
    position: relative;
    width: 14px;
    height: 14px;
    background-size: 14px 14px;
    background-repeat: no-repeat;
    background-image: url(~assets/img/common/checkbox.svg);
    background-color: transparent;
    border: none;
    z-index: 1;
    &.active{
      background-image: url(~assets/img/common/checkbox_active.svg);
 	    background-color: transparent;
      border: none;
    }

    &.is-disabled {
      cursor: not-allowed;
      /* &.active {
        background-color: #e1e3e5;
        border-color: #e1e3e5;
      } */
    }
  }
</style>
