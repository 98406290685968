<template>
  <!-- 跟单协议 -->
  <client-only>
    <div class="protocal_dialog">
      <el-dialog title="" :visible.sync="myShow" width="600px" :center="true" :close-on-click-modal="false"
                 :close-on-press-escape="false" append-to-body :custom-class="'bg-dialog protocal_dialog ' + newTheme">
        <div class="bg-dialog-title">
          {{ $t("copy_trading.text_follow_order_rule_title") }}
        </div>
        <VuePerfectScrollbar class="dialog-context" :settings="settings" tagname="div">
          <p v-html="$t('copy_trading_protocol.title_tips')"></p>
          <p class="dialog-context-subtitle" v-html="$t('copy_trading_protocol.context_1')"></p>
          <p v-html="$t('copy_trading_protocol.context_1_1')"></p>
          <p v-html="$t('copy_trading_protocol.context_1_2')"></p>
          <p v-html="$t('copy_trading_protocol.context_1_3')"></p>
          <p v-html="$t('copy_trading_protocol.context_1_4')"></p>
          <p class="dialog-context-subtitle" v-html="$t('copy_trading_protocol.context_2')"></p>
          <p v-html="$t('copy_trading_protocol.context_2_1')"></p>
          <p class="dialog-context-subtitle" v-html="$t('copy_trading_protocol.context_3')"></p>
          <p v-html="$t('copy_trading_protocol.context_3_1')"></p>
          <p class="dialog-context-subtitle" v-html="$t('copy_trading_protocol.context_4')"></p>
          <p v-html="$t('copy_trading_protocol.context_4_1')"></p>
          <p v-html="$t('copy_trading_protocol.context_4_2')"></p>
          <p v-html="$t('copy_trading_protocol.context_4_3')"></p>
          <p v-html="$t('copy_trading_protocol.context_4_5')"></p>
          <p v-html="$t('copy_trading_protocol.context_4_6')"></p>
          <p v-html="$t('copy_trading_protocol.context_4_7')"></p>
          <p v-html="$t('copy_trading_protocol.context_4_8')"></p>
          <p v-html="$t('copy_trading_protocol.context_4_9')"></p>
          <p v-html="$t('copy_trading_protocol.context_4_10')"></p>
        </VuePerfectScrollbar>
        <div slot="footer" class="dialog-footer clearfix">
          <div class="contract-check-wrap">
            <div class="contract-check">
              <client-only>
                <check-style class="check-protocol" :isChecked.sync="checked" @click="checked = !checked"></check-style>
              </client-only>
              <span class="contract-protoclo-link" v-html="$t('contract.protocol.btn_check')"></span>
            </div>
          </div>
          <el-button type="primary" :disabled="!checked" class="contract-btn" @click="openFollow" >{{ $t("common.btn_ok") }}</el-button>
        </div>
      </el-dialog>
    </div>
  </client-only>
</template>

<script>
import CheckStyle from "./CheckStyle";
import { mapState } from 'vuex';
import { openFollow, openCopyFollow } from "~/utils/utils";
export default {
  components: {
    CheckStyle
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isMix: { // 由于普通跟单和专业跟单用同一个协议，加个类型区分
      type: Boolean
    }
  },
  data() {
    return {
      checked: false,
      settings: {
        maxScrollbarLength: 100
      }
    };
  },
  computed: {
    myShow: {
      get() {
        return this.show;
      },
      set(val) {
        this.$emit("update:show", val);
        if (!val) {
          this.checked = false;
          this.$emit("close");
        }
      }
    },
    ...mapState(['user', "newTheme"]),
  },
  methods: {
    /**
     * 开通跟单协议
     */
    openFollow() {
      if(this.isMix) {
        openCopyFollow(this.user.userId);
      } else {
        openFollow();
      }
      this.myShow = false;
      this.$emit("opened");
    }
  }
};
</script>

<style lang="less">
@media screen and (min-width: 769px) {
  .protocal_dialog {
    padding: 48px;
    border-radius: 24px;

    .el-dialog__header {
      .el-dialog__headerbtn {
        top: 44px !important;
        right: 42px !important;

        .el-dialog__close {
          font-size: 24px;
          font-weight: bold;
        }
      }
    }

    .bg-dialog-title {
      font-size: 30px;
      line-height: 30px;
      font-weight: 500;
      color: @text-black;
    }

    .dialog-context {
      height: 295px;
      margin: 20px 0 0;
      padding: 20px;
      //border: 1px solid #ebebeb;
      border-radius: 2px;

      p {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 24px;
        color: #697281;

        &.dialog-context-subtitle {
          font-weight: bold;
        }
      }
    }

    .contract-check-wrap {
      float: left;
      margin-top: 8px;

      .contract-check {
        font-size: 14px;
        color: @gray-P1;

        .check-protocol {
          float: left;
          width: 14px;
          height: 14px;
          margin-right: 5px;
          margin-top: 1px;
          //border: 1px solid #d9d9d9;
          cursor: pointer;

          &.active {
            border-color: #5684f4;
          }
        }
      }

      .contract-protoclo-link {
        display: block;
        overflow: hidden;
        font-size: 12px;
        color: @black-brand;

        a {
          color: @black-brand-Heavy;
        }
      }
    }

    .dialog-footer {
      display: block;

      .contract-btn {
        min-width: 120px;
        float: right;
      }
    }

    &.black {
      .bg-dialog-title {
        font-size: 30px;
        line-height: 30px;
        font-weight: 500;
        color: @text-white !important;
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .protocal_dialog {
    display: none;
  }
}
</style>
